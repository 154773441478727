import React, { useState, useEffect } from 'react'
import '../assets/css/Membresia.css'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import CardPlanMembresia from '../components/CardPlanMembresia'
import CardCursoMuestraMembresia from '../components/CardCursoMuestraMembresia'
import ElementoBeneficios from '../components/ElementoBeneficios'
import PreguntasFrecuentes from '../components/PreguntasFrecuentes'

import Swal from 'sweetalert2'
import ReactLoading from 'react-loading'

import getMembershipPricing from '../services/getMembershipPricing'
import getCoursesId from '../services/getCoursesByCategory'

import Teachers from '../components/teachers/Teachers'
import { Helmet } from 'react-helmet-async'

export default function Membresia(props) {
    let idUsuario = localStorage.getItem('idUsuario')
    let statusMembresia = localStorage.getItem('statusMembresia')

    const [membresiaMensual, setMembresiaMensual] = useState([])
    const [membresiaSemestral, setMembresiaSemestral] = useState([])
    const [membresiaAnual, setMembresiaAnual] = useState([])

    const [tituloMensual, setTituloMensual] = useState('Membresía')
    const [tituloSemestral, setTituloSemestral] = useState('Membresía')
    const [tituloAnual, setTituloAnual] = useState('Membresía')

    const [precioMensual, setPrecioMensual] = useState(100)
    const [precioSemestral, setPrecioSemestral] = useState(100)
    const [precioAnual, setPrecioAnual] = useState(100)

    const [precioAnteriorMensual, setPrecioAnteriorMensual] = useState(100)
    const [precioAnteriorSemestral, setPrecioAnteriorSemestral] = useState(100)
    const [precioAnteriorAnual, setPrecioAnteriorAnual] = useState(100)

    const [materialesDentales, setMaterialesDentales] = useState([])
    const [habilidadesEmpresariales, setHabilidadesEmpresariales] = useState([])
    const [formacionTecnica, setFormacionTecnica] = useState([])
    const [formacionClinica, setFormacionClinica] = useState([])

    const [currency, setCurrency] = useState('MXN')

    const [txtButton, setTxtButton] = useState('Pagar membresía')

    const [planSelected, setPlanSelected] = useState(null)

    let descuento = 0.512

    const state = {
        responsive: {
            0: {
                items: 1,
                stagePadding: 50,
            },
            600: {
                items: 3,
                stagePadding: 30,
            },
            1000: {
                items: 5,
                stagePadding: 30,
            },
        },
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = 'Cursodontic | Membresía';
        getPricing()
        getCoursesById(1)
        getCoursesById(2)
        getCoursesById(3)
        getCoursesById(4)
    }, [props.login])

    let preguntas = [
        {
            id_pregunta: 1,
            pregunta: '1. ¿Qué cursos puedo ver al adquirir la membresía?',
            respuesta: 'Al adquirir la membresía, tendrás acceso a todos nuestros cursos disponibles.',
        },
        {
            id_pregunta: 2,
            pregunta: '2. ¿Cómo puedo suscribirme a la membresía?',
            respuesta:
                'Para suscribirte a la membresía, simplemente haz clic en el plan de membresía anual,regístrate con tus datos completos y serás redirigido a una página segura para realizar el pago con tu tarjeta. Brinda tus datos para realizar el pago y listo. ¡Aprende todo lo que tú quieras!',
        },
        {
            id_pregunta: 3,
            pregunta: '3. ¿Cómo puedo adquirir un curso?',
            respuesta:
                'Para adquirir un curso, inicia sesión en la página o aplicación móvil utilizando tu cuenta de Google, Facebook o tu correo electrónico registrado. Una vez que estés registrado, selecciona el curso que más te interese desde la sección de nuestros cursos y presiona el botón "Inscribirme".',
        },
        {
            id_pregunta: 4,
            pregunta: '4. ¿Puedo obtener un certificado al terminar el curso?',
            respuesta:
                'Los certificados solo se otorgan a los alumnos que han completado un curso al 100% con la membresia activa o que hayan comprado el curso. Para los cursos gratis solo necesitas completar el curso al 100%.',
        },
        {
            id_pregunta: 5,
            pregunta: '5. ¿Cómo se toman los cursos?',
            respuesta:
                'Nuestros cursos son 100% en línea, donde podrás verlos en el lugar y hora que tú decidas, las 24 horas del día, los 7 días de la semana. Esta es la ventaja de Cursodontic, una vez que los adquieres tú decides tu ritmo de aprendizaje. Una vez que te inscribes a un curso, puedes encontrarlo en la sección de Mis cursos.',
        },
        {
            id_pregunta: 6,
            pregunta: '6. ¿Dónde se toman los cursos?',
            respuesta:
                'Puedes tomar nuestros cursos desde la misma página web o descargando nuestra aplicación móvil llamada "Cursodontic" desde Google Play Store y Apple Store. Tendrás acceso a tus cursos en cualquier plataforma que elijas, y podrás aprender desde cualquier lugar que te resulte más conveniente.',
        },
        {
            id_pregunta: 7,
            pregunta: '7. ¿Cuánto tiempo tengo acceso a los cursos?',
            respuesta:
                'Tendrás acceso ilimitado a los cursos mientras mantengas tu membresía activa. Puedes aprender a tu propio ritmo y revisar el contenido tantas veces como desees mientras tu membresía esté vigente.',
        },
        {
            id_pregunta: 8,
            pregunta: '8. ¿Puedo cancelar mi membresía en cualquier momento?',
            respuesta:
                'Sí, puedes cancelar tu membresía en cualquier momento desde la sección de membresía en "Mi perfil".',
        },
        {
            id_pregunta: 9,
            pregunta: '9. ¿Puedo obtener un reembolso?',
            respuesta:
                'Las renovaciones automáticas no son reembolsables. Sin embargo, si deseas solicitar un reembolso por cualquier motivo, puedes hacerlo por cualquiera de nuestros medios de contacto dentro de un lapso de 24 horas después de adquirir la membresía.',
        },
        {
            id_pregunta: 10,
            pregunta: '10. ¿Los cursos se pueden descargar?',
            respuesta:
                'Estamos trabajando en la función de descarga dentro de nuestra aplicación para que puedas acceder a los cursos sin conexión en el futuro. Por ahora, necesitas estar conectado a internet para acceder a los cursos. Cualquier mal uso del contenido resultará en sanciones.',
        },
    ]

    function getCoursesById(id) {
        let fromData = new FormData()

        fromData.append('idCategoria', id)

        switch (id) {
            case 1:
                getCoursesId(fromData).then(response => {
                    let _respuesta = JSON.parse(response)

                    if (_respuesta.response === 'success') {
                        setMaterialesDentales(_respuesta.cursos)
                    } else {
                        Swal.fire('Error del servidor', 'Inténtalo de nuevo en uno momentos.', 'error')
                    }
                })

                break

            case 2:
                getCoursesId(fromData).then(response => {
                    let _respuesta = JSON.parse(response)

                    if (_respuesta.response === 'success') {
                        setHabilidadesEmpresariales(_respuesta.cursos)
                    } else {
                        Swal.fire('Error del servidor', 'Inténtalo de nuevo en uno momentos.', 'error')
                    }
                })
                break

            case 3:
                getCoursesId(fromData).then(response => {
                    let _respuesta = JSON.parse(response)

                    if (_respuesta.response === 'success') {
                        setFormacionTecnica(_respuesta.cursos)
                    } else {
                        Swal.fire('Error del servidor', 'Inténtalo de nuevo en uno momentos.', 'error')
                    }
                })
                break

            case 4:
                getCoursesId(fromData).then(response => {
                    let _respuesta = JSON.parse(response)

                    if (_respuesta.response === 'success') {
                        setFormacionClinica(_respuesta.cursos)
                    } else {
                        Swal.fire('Error del servidor', 'Inténtalo de nuevo en uno momentos.', 'error')
                    }
                })
                break

            default:
                Swal.fire('Error del servidor', 'Inténtalo de nuevo en uno momentos.', 'error')
        }
    }

    function getPricing() {
        let formData = new FormData()

        formData.append('idUsuario', idUsuario)
        formData.append('statusMembresia', statusMembresia)

        getMembershipPricing(formData)
            .then(response => {
                let _respuesta = JSON.parse(response)

                if (_respuesta.response === 'success') {
                    if (_respuesta.mensual['disabledButton'] === true) {
                        let button = document.querySelectorAll('#btnMembresia')
                        // button[0].setAttribute("disabled", "");
                        // button[1].setAttribute("disabled", "");
                        // button[2].setAttribute("disabled", "");
                        // button[3].setAttribute("disabled", "");

                        setTxtButton('Ya eres PREMIUM')
                    }

                    setCurrency(_respuesta.mensual['divisa'])

                    setMembresiaMensual(_respuesta.mensual)
                    setTituloMensual(_respuesta.mensual['titulo'])
                    let precioAuxMensual = _respuesta.mensual['precioblackfridayMensual']
                    // setPrecioAnteriorMensual(precioAuxMensual);
                    // setPrecioMensual(precioAuxMensual - (precioAuxMensual * descuento / 100));
                    setPrecioAnteriorMensual((precioAuxMensual / (1 - descuento)).toFixed(0))
                    setPrecioMensual(precioAuxMensual)

                    setMembresiaSemestral(_respuesta.semestral)
                    setTituloSemestral(_respuesta.semestral['titulo'])
                    let precioAuxSemestral = _respuesta.semestral['precioblackfridaySemestral']
                    // setPrecioAnteriorSemestral(precioAuxSemestral);
                    // setPrecioSemestral(precioAuxSemestral - (precioAuxSemestral * descuento / 100));
                    setPrecioAnteriorSemestral((precioAuxSemestral / (1 - descuento)).toFixed(0))
                    setPrecioSemestral(precioAuxSemestral)

                    setMembresiaAnual(_respuesta.anual)
                    setTituloAnual(_respuesta.anual['titulo'])
                    let precioAuxAnual = _respuesta.anual['precioblackfridayAnual']
                    // setPrecioAnteriorAnual(precioAuxAnual);
                    // setPrecioAnual(precioAuxAnual - (precioAuxAnual * descuento / 100));
                    setPrecioAnteriorAnual((precioAuxAnual / (1 - descuento)).toFixed(0))
                    setPrecioAnual(precioAuxAnual)
                } else {
                    Swal.fire('Error del servidor', 'Inténtalo de nuevo en uno momentos.', 'error')
                }
            })
            .catch(error => {
                Swal.fire('Error del servidor', 'Inténtalo de nuevo en uno momentos.', 'error')
                console.log(error)
            })
    }

    return membresiaMensual.length === 0 || membresiaSemestral.length === 0 || membresiaAnual.length === 0 ? (
        <div className="circularBarProgressIndicator">
            <ReactLoading
                type="spin"
                color="#F65F5D"
                height={'50px'}
                width={'50px'}
                className="circularBarProgressIndicatorItem"
            />
        </div>
    ) : (
        <div className="container-fluid" id="membresia">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Membresía | Cursodontic</title>
                <meta name="description" content="Planes de membresía en cursodontic" />
                <meta name="keywords" content="Membresía cursodontic" />
                <meta name="author" content="Cursodontic" />

                <meta property="og:title" content="Membresía| Cursodontic" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
                <meta property="og:url" content="https://cursodontic.com" />
                <meta name="twitter:card" content="Cursodontic" />

                <meta property="og:description" content="Planes de membresía en cursodontic" />
                <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
            </Helmet>

            <div className="container noPadding-xs noPadding-sm noPadding-md">
                <h1 className="membresia__titulo">
                    Membresia <span className="membresia__premium">PREMIUM</span>
                </h1>

                <p className="membresia__descripcion">
                    Somos la mejor opción en capacitación online para el área dental, al mejor precio.
                </p>

                <section className="precios">
                    {/* <h2 className="precios__subtitulo">Encuentra tu plan ideal</h2> */}

                    <div className="planes" id="planes">
                        <div className="row">
                            <div className="col-xl col-lg col-md-0 col-sm-0 col-0">
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                <CardPlanMembresia
                                    titulo={tituloAnual}
                                    descuento={descuento}
                                    precio={Math.round(precioAnual)}
                                    precioAnterior={1950}
                                    precioMensual={Math.round(precioAnual / 12)}
                                    tipo="planes__primario"
                                    currency={currency}
                                    txtButton={txtButton}
                                    nombrePlan="anual"
                                    setPlanSelected={setPlanSelected}
                                    setModalLogin={props.setModalLogin}
                                    login={props.login}
                                />
                            </div>
                            <div className="col-xl col-lg col-md-0 col-sm-0 col-0">
                            </div>
                        </div>
                    </div>
                </section>

                <section className="beneficios">
                    <h2 className="beneficios__titulo">
                        Beneficios de la Membresía <span className="membresia__premium">PREMIUM</span>
                    </h2>

                    <div className="beneficios__elementos">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <ElementoBeneficios
                                    icono={<i className="fas fa-calendar-check"></i>}
                                    descripcion="Contenido siempre disponible"
                                />
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <ElementoBeneficios
                                    icono={<i className="fas fa-play"></i>}
                                    descripcion="Acceso completo a +50 cursos"
                                />
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <ElementoBeneficios
                                    icono={<i className="fas fa-question"></i>}
                                    descripcion="Resolvemos tus dudas"
                                />
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <ElementoBeneficios
                                    icono={<i className="fas fa-award"></i>}
                                    descripcion="Certificado de finalización"
                                />
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <ElementoBeneficios
                                    icono={<i className="fas fa-mobile-alt"></i>}
                                    descripcion="Disponible en cualquier dispositivo"
                                />
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <ElementoBeneficios
                                    icono={<i className="fas fa-ban"></i>}
                                    descripcion="Cancela cuando quieras"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cursos">
                    <h2 className="cursos__titulo">Accede a +50 cursos en 3 categorías diferentes</h2>
                    <div className="cursos__sugerencias">
                        <nav>
                            <div className="nav nav-pills cursos__categorias" id="nav-tab" role="tablist">
                                <a
                                    className="nav-link cursos__linkCategoria active"
                                    id="nav-masVisto-tab"
                                    data-toggle="tab"
                                    href="#nav-masVisto"
                                    role="tab"
                                    aria-controls="nav-masVisto"
                                    aria-selected="true"
                                >
                                    Más vistos
                                </a>
                                <a
                                    className="nav-link cursos__linkCategoria"
                                    id="nav-formacionClinica-tab"
                                    data-toggle="tab"
                                    href="#nav-formacionClinica"
                                    role="tab"
                                    aria-controls="nav-formacionClinica"
                                    aria-selected="false"
                                >
                                    Formación Clínica
                                </a>
                                <a
                                    className="nav-link cursos__linkCategoria"
                                    id="nav-formacionTecnica-tab"
                                    data-toggle="tab"
                                    href="#nav-formacionTecnica"
                                    role="tab"
                                    aria-controls="nav-formacionTecnica"
                                    aria-selected="false"
                                >
                                    Formación Técnica
                                </a>
                                <a
                                    className="nav-link cursos__linkCategoria"
                                    id="nav-habilidadesEmpresariales-tab"
                                    data-toggle="tab"
                                    href="#nav-habilidadesEmpresariales"
                                    role="tab"
                                    aria-controls="nav-habilidadesEmpresariales"
                                    aria-selected="false"
                                >
                                    Habilidades Empresariales
                                </a>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="nav-masVisto"
                                role="tabpanel"
                                aria-labelledby="nav-masVisto-tab"
                            >
                                {materialesDentales.length >= 1 && (
                                    <OwlCarousel
                                        className="owl-theme cursos__carrusel"
                                        items="1"
                                        margin={10}
                                        autoplay={false}
                                        loop={true}
                                        dots={true}
                                        touchDrag={true}
                                        animateIn={true}
                                        responsive={state.responsive}
                                        center={true}
                                    >
                                        {materialesDentales.map(dataCurso => {
                                            return (
                                                <CardCursoMuestraMembresia
                                                    titulo={dataCurso.tituloCurso}
                                                    instructor={dataCurso.nombre}
                                                    linkCurso={dataCurso.linkCurso}
                                                    imagenCurso={dataCurso.imagenCurso}
                                                />
                                            )
                                        })}
                                    </OwlCarousel>
                                )}
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-formacionClinica"
                                role="tabpanel"
                                aria-labelledby="nav-formacionClinica-tab"
                            >
                                {formacionClinica.length >= 1 && (
                                    <OwlCarousel
                                        className="owl-theme cursos__carrusel"
                                        items="2"
                                        margin={10}
                                        autoplay={false}
                                        loop={true}
                                        dots={true}
                                        touchDrag={true}
                                        animateIn={true}
                                        responsive={state.responsive}
                                        center={false}
                                    >
                                        {formacionClinica.map(dataCurso => {
                                            return (
                                                <CardCursoMuestraMembresia
                                                    titulo={dataCurso.tituloCurso}
                                                    instructor={dataCurso.nombre}
                                                    linkCurso={dataCurso.linkCurso}
                                                    imagenCurso={dataCurso.imagenCurso}
                                                />
                                            )
                                        })}
                                    </OwlCarousel>
                                )}
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-formacionTecnica"
                                role="tabpanel"
                                aria-labelledby="nav-formacionTecnica-tab"
                            >
                                {formacionTecnica.length >= 1 && (
                                    <OwlCarousel
                                        className="owl-theme cursos__carrusel"
                                        items="2"
                                        margin={10}
                                        autoplay={false}
                                        loop={true}
                                        dots={true}
                                        touchDrag={true}
                                        animateIn={true}
                                        responsive={state.responsive}
                                        center={false}
                                    >
                                        {formacionTecnica.map(dataCurso => {
                                            return (
                                                <CardCursoMuestraMembresia
                                                    titulo={dataCurso.tituloCurso}
                                                    instructor={dataCurso.nombre}
                                                    linkCurso={dataCurso.linkCurso}
                                                    imagenCurso={dataCurso.imagenCurso}
                                                />
                                            )
                                        })}
                                    </OwlCarousel>
                                )}
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-habilidadesEmpresariales"
                                role="tabpanel"
                                aria-labelledby="nav-habilidadesEmpresariales-tab"
                            >
                                {habilidadesEmpresariales.length >= 1 && (
                                    <OwlCarousel
                                        className="owl-theme cursos__carrusel"
                                        items="2"
                                        margin={10}
                                        autoplay={false}
                                        loop={true}
                                        dots={true}
                                        touchDrag={true}
                                        animateIn={true}
                                        responsive={state.responsive}
                                        center={false}
                                    >
                                        {habilidadesEmpresariales.map(dataCurso => (
                                            <CardCursoMuestraMembresia
                                                titulo={dataCurso.tituloCurso}
                                                instructor={dataCurso.nombre}
                                                linkCurso={dataCurso.linkCurso}
                                                imagenCurso={dataCurso.imagenCurso}
                                            />
                                        ))}
                                    </OwlCarousel>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="expertos">
                    <h2 className="expertos__titulo">Conoce a nuestros profesores</h2>

                    <Teachers />
                </section>

                <section className="preguntas">
                    <h1 className="preguntas__titulo text-center font-weight-bold pb-4">Preguntas frecuentes</h1>

                    <PreguntasFrecuentes preguntas={preguntas} />
                </section>
            </div>
        </div>
    )
}
